<!-- 明厨亮灶大屏页面 -->
<template>
  <div class='content'>
    <!-- 全屏时展示顶部 -->
    <div class="city-park-command-center-map-heard" v-if="isFullScreen">
      <div class="left_weather_box">
      </div>
      <div class="heard-img-box">
        <span class="count"> 明厨亮灶
        <!-- {{
          projectInfo && projectInfo.platform_name
            ? projectInfo.platform_name
            : "城市级停车指挥中心"
          }} -->
        </span>
      </div>
      <span class="icon_box">
        <i class="iconfont lebo-guanbi" @click="closeBigScreen"></i>
      </span>
    </div>
    <!-- 背景地图 -->
    <!-- :style="{'height':this.height+ 'px','width':this.width +'px'}" -->
    <div class="mainBox">
      <el-amap vid="map" ref="map" :zoom="zoom" :center="center" class="backMap"  :events="mapEvents"
          pitch-enable="false" >
          <el-amap-marker v-for="(marker,index) in markers" :key="index" :events="marker.events" :position="marker.position" :icon="iconImg"/>
          <el-amap-info-window
            v-if="window"
            :position="window.position"
            :visible="window.visible"
            :content="window.content"
            :offset="window.offset"
            :close-when-click-map="true"
            :is-custom="true"
          >
            <div id="info-window" style="white-space: pre-wrap;">
              <p>{{ window.address }}</p>
            </div>
          </el-amap-info-window>
      </el-amap>
      <!-- 地图表面组件展示图 -->
      <div class="toolboxes" >
        <chartsBarBox :class="isFullScreen?'barBoxFull':'barBox'"></chartsBarBox>
        <cctvBox :class="isFullScreen?'cctvBoxFull':'cctvBox'"></cctvBox>
        <chartPieBox :class="isFullScreen?'pieBoxFull':'pieBox'"></chartPieBox>
        <chartLineBox :class="isFullScreen?'lineBoxFull':'lineBox'"></chartLineBox>
        <issueBox :class="isFullScreen?'issueBoxFull':'issueBox'"></issueBox>
        <numberBox :class="isFullScreen?'numberbox1Full':'numberbox1'">
          <template slot="topNumber">{{ sceneCount }}</template>
          <template slot="footName">场景</template>
        </numberBox>
        <numberBox :class="isFullScreen?'numberbox2Full':'numberbox2'">
          <template slot="topNumber">{{ deviceCount }}</template>
          <template slot="footName">设备</template>
        </numberBox>
        <numberBox :class="isFullScreen?'numberbox3Full':'numberbox3'">
          <template slot="topNumber">{{ recordCount }}</template>
          <template slot="footName">告警次数</template>
        </numberBox>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import chartsBarBox from './components/chartsBarBox.vue'
import cctvBox from './components/cctvBox.vue'
import chartPieBox from './components/chartPieBox.vue'
import chartLineBox from './components/chartLineBox.vue'
import issueBox from './components/issueBox.vue'
import numberBox from './components/numberBox.vue'
// import { lazyAMapApiLoaderInstance } from 'vue-amap'
import { getLocationMap, getStatistical } from '@/api/mclzapi'
import iconPinc from '@/assets/img/mclz/mapicon.png'
import { lazyAMapApiLoaderInstance } from 'vue-amap'
export default {
  name: 'kitchenBigScreen',
  components: {
    chartsBarBox,
    cctvBox,
    chartPieBox,
    chartLineBox,
    issueBox,
    numberBox
  },
  props: {},
  data () {
    const self = this
    return {
      label: {
        content: '地址名',
        offset: [10, -10]
      },
      width: '',
      height: '',
      mapEvents: {
        init (o) {
          lazyAMapApiLoaderInstance.load().then(() => {
            self.point()
          })
          o.setMapStyle('amap://styles/e4500f6a8fc6db8c271dc0f1250134e3')
        },
        zoomchange (e, i) {
          console.log(e, i, '地图缩放')
          console.log(self.$refs.map.$$getInstance().getZoom())
          // 级别
          self.zoom = self.$refs.map.$$getInstance().getZoom()
          //  lebel=this.$refs.GdMap.$$getInstance().getZoom()
        },
        moveend (e, i) {
          console.log('地图移动', self.$refs.map.$$getInstance().getCenter())
          self.center = []
          console.log('selef', self.center)
        }
      },
      // 统计数据设备
      deviceCount: 0,
      recordCount: 0,
      sceneCount: 0,
      data: [],
      zoom: 3,
      center: [121.599197, 31.205379],
      markers: [],
      windows: [],
      window: '',
      iconImg: iconPinc
    }
  },
  watch: {},
  computed: {
    ...mapState(['isFullScreen'])
  },
  methods: {
    ...mapMutations(['changeScreenStatus', 'setShowModule']),
    // 获取窗口可视宽高
    // getClient () {
    //   // this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
    //   this.width = window.innerWidth
    //   // this.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    //   this.height = window.innerHeight
    //   console.log('宽', this.width)
    //   console.log('高', this.height)
    // },
    // 获取地图所在位置
    async fngetLocationMap () {
      const res = await getLocationMap()
      console.log('location', res)
      const locationList = res.Data.forEach(item => {
        this.data.push({
          position: item.longitude + ',' + item.latitude,
          address: item.scene_name,
          pointAdress: item.position
        })
      })
      this.point() // 得到数据遍历
    },
    point () {
      const markers = []
      const windows = []
      const that = this
      this.data.forEach((item, index) => {
        var markpositon = item.position.split(',')
        markers.push({
          position: item.position.split(','),
          //   icon:require('../assets/logo.png'), //不设置默认蓝色水滴
          events: {
            click: () => {
              // that.$refs.map.$$getInstance().setFitView()
              // that.$refs.map.$amap.setFitView()
              that.zoom = 13
              that.center = markpositon
              console.log('11', that.zoom, markpositon)
              that.windows.forEach(window => {
                window.visible = false // 关闭窗体
              })
              that.window = that.windows[index]
              that.$nextTick(() => {
                that.window.visible = true
              })
            }
            // mouseover () {
            //   // 方法：鼠标移动到点标记上，显示相应窗体
            //   that.windows.forEach(window => {
            //     window.visible = false // 关闭窗体
            //   })
            //   that.window = that.windows[index]
            //   that.$nextTick(() => {
            //     that.window.visible = true
            //   })
            // }
            // mouseout () {
            //   that.windows.forEach(window => {
            //     window.visible = false // 关闭窗体
            //   })
            // }

          }
        })
        windows.push({
          position: item.position.split(','),
          isCustom: true,
          offset: [15, 55], // 窗体偏移
          showShadow: false,
          visible: false, // 初始是否显示
          address: item.address + '\n' + item.pointAdress
        })
      })
      //  加点
      this.markers = markers
      // 加弹窗
      this.windows = windows
    },
    // 获取统计数量设备
    async fngetStatistical () {
      const res = await getStatistical()
      // console.log('Statistical', res)

      this.deviceCount = res.Data.deviceCount
      this.recordCount = res.Data.recordCount
      this.sceneCount = res.Data.sceneCount
    },
    // 解决地图刷新白屏问题
    clearMapstore () {
      localStorage.removeItem('_AMap_vectorlayer')
      localStorage.removeItem('_AMap_wgl')
      localStorage.removeItem('_AMap_sync')
      localStorage.removeItem('_AMap_raster')
      localStorage.removeItem('_AMap_overlay')
      localStorage.removeItem('_AMap_mouse')
      localStorage.removeItem('_AMap_AMap.ToolBar')
      localStorage.removeItem('_AMap_AMap.Scale')
      localStorage.removeItem('_AMap_AMap.RangingTool')
      localStorage.removeItem('_AMap_AMap.PolyEditor')
      localStorage.removeItem('_AMap_AMap.PlaceSearch')
      localStorage.removeItem('_AMap_AMap.OverView')
      localStorage.removeItem('_AMap_AMap.MouseTool')
      localStorage.removeItem('_AMap_AMap.MarkerClusterer')
      localStorage.removeItem('_AMap_AMap.MapType')
      localStorage.removeItem('_AMap_AMap.Geolocation')
      localStorage.removeItem('_AMap_AMap.CitySearch')
      localStorage.removeItem('_AMap_AMap.CircleEditor')
      localStorage.removeItem('_AMap_AMap.Autocomplete')
      localStorage.removeItem('_AMap_AMap.IndoorMap3D')
      localStorage.removeItem('_AMap_Map3D')
      localStorage.removeItem('_AMap_labelcanvas')
      localStorage.removeItem('_AMap_labelDir')
      localStorage.removeItem('_AMap_data.tileKeys')
      localStorage.removeItem('_AMap_AMap.CustomLayer')
      localStorage.removeItem('_AMap_AMap.Geocoder')
      localStorage.removeItem('_AMap_AMap.CustomLayer')
      localStorage.removeItem('_AMap_AMap.IndoorMap')
      localStorage.removeItem('_AMap_anole')
      localStorage.removeItem('_AMap_cmng')
      localStorage.removeItem('_AMap_cvector')
    },
    // 退出全屏
    closeBigScreen () {
      this.changeScreenStatus(false)
      // console.log('退出全屏')
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      } else {
        window.parent.showTopBottom()
      }
    }
  },
  created () {
    this.clearMapstore()
  },

  mounted () {
    // this.$nextTick(() => {
    //   this.getClient()
    // })
    this.fngetLocationMap()
    this.fngetStatistical()
  }
}
</script>

<style scoped lang="less">
.content {
  width: 100%;
  height: 100%;

  .mainBox {
    width: 100%;
    height: 100%;
    position: relative;

    .backMap {
      width: 100%;
      height: 100%;
    }
    .toolboxes {
    .barBox {
      position: absolute;
      width: 470px;
      height: 280px;
      top: 10px;
      left: 10px;
      // background-color: pink;
      background-image: url('~@/assets/img/mclz/chartsbackground.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: bottom left;
    }
    .barBoxFull {
      position: absolute;
      width: 470px;
      height: 320px;
      top: 60px;
      left: 10px;
      // background-color: pink;
      background-image: url('~@/assets/img/mclz/chartsbackground.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: bottom left;
    }
    .cctvBox {
      position: absolute;
      width: 470px;
      height: 280px;
      left: 10px;
      top: 300px;
      // background-color: pink;
      background-image: url('~@/assets/img/mclz/chartsbackground.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: bottom left;
    }
    .cctvBoxFull {
      position: absolute;
      width: 470px;
      height: 320px;
      left: 10px;
      top: 390px;
      // background-color: pink;
      background-image: url('~@/assets/img/mclz/chartsbackground.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: bottom left;
    }

    .pieBox {
      position: absolute;
      width: 470px;
      height: 280px;
      top: 590px;
      left: 10px;
      // background-color: pink;
      background-image: url('~@/assets/img/mclz/chartsbackground.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: bottom left;
    }
    .pieBoxFull {
      position: absolute;
      width: 470px;
      height: 320px;
      top: 720px;
      left: 10px;
      // background-color: pink;
      background-image: url('~@/assets/img/mclz/chartsbackground.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: bottom left;
    }

    .lineBox {
      position: absolute;
      width: 950px;
      height: 280px;
      top: 590px;
      left: 490px;
      // background-color: pink;
      background-image: url('~@/assets/img/mclz/linebackground.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: bottom left;
    }
    .lineBoxFull {
      position: absolute;
      width: 950px;
      height: 320px;
      top: 720px;
      left: 490px;
      // background-color: pink;
      background-image: url('~@/assets/img/mclz/linebackground.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: bottom left;
    }

    .issueBox {
      position: absolute;
      width: 460px;
      height: 860px;
      top: 10px;
      left: 1445px;
      // background: pink;
      background-image: url('~@/assets/img/mclz/issuesbackground.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: bottom left;
    }
    .issueBoxFull {
      position: absolute;
      width: 460px;
      height: 980px;
      top: 60px;
      left: 1445px;
      // background: pink;
      background-image: url('~@/assets/img/mclz/issuesbackground.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: bottom left;
    }

    .numberbox1 {
      position: absolute;
      top: 10px;
      left: 600px;
      width: 180px;
      height: 70px;
    }
    .numberbox1Full {
      position: absolute;
      top: 60px;
      left: 600px;
      width: 180px;
      height: 70px;
    }
    .numberbox2 {
      position: absolute;
      top: 10px;
      left: 870px;
      width: 180px;
      height: 70px;
    }
    .numberbox2Full {
      position: absolute;
      top: 60px;
      left: 870px;
      width: 180px;
      height: 70px;
    }
    .numberbox3 {
      position: absolute;
      top: 10px;
      left: 1170px;
      width: 180px;
      height: 70px;
    }
    .numberbox3Full {
      position: absolute;
      top: 60px;
      left: 1170px;
      width: 180px;
      height: 70px;
    }
  }
  }

}

.icon_box {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);

  .iconfont {
    font-size: 30px;
    margin-right: 20px;
    color: @homeTextColor;
    cursor: pointer;
  }
}

/deep/ .city-park-command-center-map-heard {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 70px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  .left_weather_box {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;

    .weather_img {
      height: 45px;
    }

    .city_box,
    .weather_box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      margin-left: 12px;

      .title01 {
        font-size: 18px;
        color: @homeTextColor;
      }

      .title02 {
        font-size: 14px;
        color: @homeTextColor;
      }
    }
  }

  .heard-img-box {
    width: 60%;
    height: 100%;
    // background-image: url("../../../../assets/images/map-big.png");
    background-image: url("~@/assets/images/map-big.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;

    .count {
      font-size: 48px;
      line-height: 38px;
      font-family: YouSheBiaoTiYuan;
    // font-size: 0.11458rem;
    color: #39d7ef;
    background: -webkit-linear-gradient(top, #00fff6, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    }
  }

  .icon_box {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);

    .iconfont {
      font-size: 30px;
      margin-right: 20px;
      color: @homeTextColor;
      cursor: pointer;
    }
  }
}
/* 信息窗口样式 */
#info-window {
        width: 200px;
        // height: 100px;
        margin-left: -30px;
        margin-bottom: 80px;
        border-radius: 4px;
        text-align: left;
        padding: 6px 20px 20px 20px;
        // line-height: 40px;
        font-size: 14px;
font-family: SourceHanSansCN-Bold, SourceHanSansCN;
font-weight: bold;
color: #FBB12D;
        position: relative;
        overflow: hidden;
       background-image: url('~@/assets/img/mclz/infoback.png');
       background-size: 100% 100%;
    }
/* 修改标记点样式 */
    .row,#map {
  height: 100%;
}
/* 需要添加 /deep/ 修改标记点样式 */
/deep/.amap-icon img {
  max-width: 20px !important;
  max-height: 20px !important;
}
</style>
